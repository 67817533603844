exports.onRouteUpdateDelayed = ({ location, prevLocation }) => {
  window.document.body.style.setProperty("cursor", "wait", "important");
  window.document.body.style.setProperty("filter", "blur(15px)", "important");
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  window.document.body.style.removeProperty("cursor");
  window.document.body.style.removeProperty("filter");
  if(prevLocation != null) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ isEntry: false });
  }
  else{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ isEntry: true });
  }
};